<template>
  <div class="article-box">
    <el-table :data="agencyAdmin" style="width: 100%" v-loading="loading">
      <el-table-column prop="serial" label="序号" type="index" align="center" width="100">
        <template slot-scope="scope">
          {{ (currpage - 1) * pagesize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="AgentName" label="姓名" align="center"></el-table-column>
      <el-table-column label="城市" :formatter="Citys" align="center"></el-table-column>
      <el-table-column prop="SchoolName" label="学校" align="center"></el-table-column>
      <el-table-column prop="Remarks" label="理由" align="center"></el-table-column>
      <el-table-column prop="Tel" label="电话" align="center"></el-table-column>
      <!-- <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="agree(scope.$index, scope.row)">同意</el-button>
             <el-button
            size="mini"
            type="danger"
            @click="refuse(scope.$index, scope.row)">拒绝</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
        layout="prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import {
    bindApply
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        agencyAdmin: [], // 代理申请学校列表
        loading: false, //加载框
        currpage: 1, // 初始页
        pagesize: 12, // 每页显示条目个数
        CountNum: 0 // 总条数
      }
    },
    mounted() {
      this.applyList(1) // 获取第一页待审核数据
    },
    methods: {
      applyList(currpage) { // 代理投放学校申请列表
        this.loading = true
        const data = {
          page: currpage, // 当前页数
          limit: this.pagesize, // 每页条数
          status: 2 // 	0待审核 1已同意  2已拒绝 10全部
        }
        bindApply(data).then(res => {
          this.agencyAdmin = res.data.List // 待审核列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      Citys(row, column) { // 代理的城市
        return row.ProvinceName + row.CityName
      },
      handleCurrentChange(val) { // 分页
        this.applyList(val)
        this.currpage = val
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
